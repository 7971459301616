.App {
	width: 60%;
	margin: auto;
	height: 100vh;
}

.header {
	height: 10vh;
	display: flex;
}

.input {
	width: 80%;
}

.button {
	width: 20%;
}

.msg {
	height: 70px;
	border-bottom: 1px solid #666666;
	width: auto;
	line-height: 70px;
	font-size: 18px;
}

.chat {
	border: 1px solid black;
	width: 100%;
	height: 90%;
}
.left {
	padding-left: 20px;
}
.right {
	padding-right: 20px;
	text-align: right;
}
